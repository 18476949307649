@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;500;600;700&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Poppins", sans-serif;
}

section {
  padding: 50px 0;
}

.container {
  max-width: 1170px;
  padding-left: 20px;
  padding-right: 20px;
  margin: auto;
}

.App {
  background: linear-gradient(
    to right bottom,
    rgba(255, 228, 230, 0.2),
    rgba(204, 251, 241, 0.4)
  );
  height: 1000px;
}
